
import React from 'react';
import {  NumberInput, SelectInput,  ReferenceInput, Datagrid, NumberField,  
ReferenceField, Create, List, TextField, Edit, SimpleForm, TextInput, useListContext } from 'react-admin';

import { useRecoilState} from 'recoil';
import { selectedSensor ,selectedDevice} from './state/atoms';


const deviceFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];




export const Aside = () => {
    const { isLoading, selectedIds } = useListContext();
    const [userSelectedSensor, setSelectedSensor] = useRecoilState(selectedSensor);
    const [userSelectedDevice, setSelecteddevice] = useRecoilState(selectedDevice);
    if (isLoading) return null;
    return (
        <div>

           

            {setSelectedSensor(selectedIds)}

            {console.log(userSelectedSensor)}
        </div>
    );
};


export const SensorList = props => (
    <List {...props}    
    // aside={<Aside />}
   
    
    >
        <Datagrid rowClick="edit">
            {/* <BooleanField source="Sensor_state" /> */}

            {/* <TextField source="Sensor_device" /> */}
            {/* <BooleanField source="is_activated" /> */}
            {/* <DateField source="last_update" /> */}
            <TextField source="sensor_name" />
            <ReferenceField source="device_id" reference="device"><TextField source="devicename" /></ReferenceField>
            <NumberField source="sensor_pin" />
            <NumberField source="read_frequency" />
            {/* <TextField source="id" /> */}

        </Datagrid>
    </List>
);


export const SensorEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="Sensor_state" /> */}
            <ReferenceInput source="device_id" reference="device"><SelectInput optionText="id" /></ReferenceInput>
            {/* <TextInput source="Sensor_device" /> */}
            {/* <DateInput source="last_update" /> */}
            <TextInput source="sensor_name" />
            <NumberInput source="sensor_pin" />
            <TextInput source="read_parameter" />
            <NumberInput source="read_frequency" />
            {/* <TextInput source="id" /> */}
            {/* <BooleanInput source="is_activated" /> */}
        </SimpleForm>
    </Edit>
);

export const SensorCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="Sensor_state" /> */}
            <ReferenceInput source="device_id" reference="device"><SelectInput optionText="devicename" /></ReferenceInput>
            {/* <TextInput source="Sensor_device" /> */}
            {/* <DateInput source="last_update" /> */}
            <TextInput source="sensor_name" />
            <NumberInput source="sensor_pin" />
            <NumberInput source="read_frequency" />
            {/* <TextInput source="id" /> */}
            {/* <BooleanInput source="is_activated" /> */}
        </SimpleForm>
    </Create>
);