import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Introduction from './Introduction'
import Device1 from './Program';
import Submit from './Submit'
import { DeviceList } from '../devices'
import ConnectivityForm from './Connectivity.js'
import {useRecoilState } from 'recoil';
import Wizardtext from './wizardtext';
import '../maps/maps.css'

import { selectedDevice,  deviceConfigs} from '../state/atoms';




const steps = ['Introduction', 'Select a Device', 'Select a Device Program', 'Select Connectivity Parameters','Complete'];

export default function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [deviceSelected] =  useRecoilState(selectedDevice); 
  const [userDeviceConfigs] =  useRecoilState(deviceConfigs);
  
 

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  

  return (
  
    <Box sx={{ flex: 'center', mt: 3   }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
          
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
      
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>

          {/* <Typography variant="h6" sx={{ mt: 4, mb: 2, py: 2 }}>
           
              { activeStep == 0 ? "Introduction" : activeStep == 1 ? "Select a Device and its predefined Sensor and Actuator" :  activeStep == 2 ? "Select programming language" :  activeStep == 3 ? "Select your connectivity parameters"  : activeStep == 4 ? "Review and submit" :null }

            </Typography> */}

            <Wizardtext step={activeStep} />

          <Box sx={{ width: '100%', justifyContent: 'space-around' , alignItems: 'stretch'  }}>
          {console.log("activeStep = ", activeStep)}
           
          { activeStep == 0 ? <Introduction/> : activeStep == 1 ? <DeviceList resource="device"/> :  (activeStep == 2) && (deviceSelected.length !=0) ? <Device1  /> :   activeStep == 3 ? <ConnectivityForm /> : activeStep == 4 ? <Submit />  :   <div className='error'>You didn't select a device Please select a device</div> }
         
           {/* {getParameters(deviceSelected[0]).data.customer_devices_controls.id} */}
              {console.log("current selected device in wizard is:" , deviceSelected)}


              { (activeStep == 2) ?? console.log("current device config in wizard is:" , userDeviceConfigs)}

                         

            </Box>
            
           
        
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>

    
  );
}