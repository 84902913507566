import {
 
    atom,
   
  } from 'recoil';
  
  



  export const userProgram = atom({
    key: 'userProgram',
    default: "",
  });
  
  export const deviceSensors = atom({
    key: 'deviceSensors',
    default: [],
  });
  

  export const deviceActuators = atom({
    key: 'deviceActuator',
    default: [],
  });
  

  

  export const selectedDevice = atom({
    key: 'selectedDevices',
    default: '',
  });


  
  export const selectedSensor = atom({
    key: 'selectedSensor',
    default: [],
  });
  

  export const selectedActuator = atom({
    key: 'selectedActuator',
    default: [],
  });
  
  export const deviceConfigs = atom({
    key: 'deviceConfigs',
    default: '',
  });
  

  export const connConfigs = atom({
    key: 'connConfigs',
    default: '',
  });
  

  export const colorMode = atom({
    key: 'colorMode',
    default: '',
  });
  

  