import React from 'react';
import './Debug/ReactotronConfig'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";

import {
  RecoilRoot,
  
} from 'recoil';





const client = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
 <RecoilRoot> 
  <QueryClientProvider client={client}>  
    <App />        
  </QueryClientProvider>
 </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

