import * as React from 'react';
import Box from '@mui/material/Box';
import { ListItemText , List}  from '@mui/material';

import Typography from '@mui/material/Typography';

export default function DialogSelect() {
 
  return (

    <>

<Box sx={{ display: 'flex', justifyContent: 'center' ,  flexWrap: 'wrap',  mt: 15 }}>

      <Typography variant="h5" component="div" >
      <List>

               <ListItemText>
                <Typography variant="h5" component="div"  color={'secondary'} textAlign='center' fontFamily='monospace'>
                Welcome to SmartIOT Device Wizard
                </Typography>
                </ListItemText>

               <ListItemText>
                <Typography variant="h5" component="div" color={'secondary'} textAlign='center' fontFamily='monospace'>
                This Wizard will help you to generate a simple program to connect to SmartIOT System:
                </Typography>
                </ListItemText>
     
     
  
     

      </List>
       
      </Typography>

</Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' ,  flexWrap: 'wrap',  mt: 15 }}>
            <div sx={{ flex: 'center' ,  mt: 100, mb: 5,  }}>

             
             
          
              <List>
                <ListItemText>
                <Typography variant="h5" component="div" fontFamily='monospace' textAlign='center'>
                    First: Select a Device with its Sensors and Actuators
                </Typography>

                </ListItemText>

                <ListItemText>
                <Typography variant="h5" component="div" fontFamily='monospace' textAlign='center'>
                    Second: Select your preferred Programming Language to use on your device
                </Typography>
                </ListItemText>

                <ListItemText>
                <Typography variant="h5" component="div" fontFamily='monospace' textAlign='center'>
                    Third: Select Connectivity Parameters to connect to SmartIOT System
                </Typography>
                </ListItemText>         


              </List>
             
        
              </div>
    
      </Box>
    </>
  );
}