
import React from 'react';
import { BooleanInput, 
    NumberInput,
    SelectInput,
    ReferenceInput,
    Datagrid,
    NumberField,
    BooleanField, 
    ReferenceField, 
    Create, 
    List, 
    TextField, 
    Edit, 
    SimpleForm, 
    TextInput,
    useListContext,
    
 } 
    
    
    from 'react-admin';
    import { useRecoilState} from 'recoil';
    import { selectedActuator } from './state/atoms';


    export const Aside = () => {
        const { isLoading,  selectedIds } = useListContext();
        const [actuatorSelected, setSelectedActuator] = useRecoilState(selectedActuator);
        if (isLoading) return null;
        return (
            <div>
                {/* <Typography variant="h6">Device Controls</Typography>
                <Typography variant="body2">
                    Total views: {data.map((d) => d.device_id)}
                </Typography> */}

               { setSelectedActuator(selectedIds)}

                {console.log(actuatorSelected)}
            </div>
        );
    };

export const ControlList = props => 
{

    // const { total, data, onSelect, resource, selectedIds } = useListContext();

   
  
return (
    <List {...props}
    aside={<Aside />}
    
    
    >
        <Datagrid rowClick="edit">
            {/* <BooleanField source="control_state" /> */}
            <ReferenceField source="device_id" reference="device"><TextField source="devicename" /></ReferenceField>
            {/* <TextField source="control_device" /> */}
            <BooleanField source="is_activated" />
            {/* <DateField source="last_update" /> */}
            <TextField source="control_name" />
            <NumberField source="control_pin" />
            {/* <TextField source="id" /> */}

           
        </Datagrid>
       
    </List>
);

}

export const ControlEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="control_state" />
            <ReferenceInput source="device_id" reference="device"><SelectInput optionText="devicename" /></ReferenceInput>
            {/* <TextInput source="control_device" /> */}
            {/* <DateInput source="last_update" /> */}
            <TextInput source="control_name" />
            <NumberInput source="control_pin" />
            {/* <TextInput source="id" /> */}
            <BooleanInput source="is_activated" />
        </SimpleForm>
    </Edit>
);

export const ControlCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="control_state" /> */}
            <ReferenceInput source="device_id" reference="device"><SelectInput optionText="devicename" /></ReferenceInput>
            {/* <TextInput source="control_device" /> */}
            {/* <DateInput source="last_update" /> */}
            <TextInput source="control_name" />
            <NumberInput source="control_pin" />
            {/* <TextInput source="id" /> */}
            <BooleanInput source="is_activated" />
        </SimpleForm>
    </Create>
);