import React from 'react';

import './framestyle.css'


var cardStyle = {
    display: 'block',
    width: '100%',
    transitionDuration: '0.3s',
    height: '45vw'
}
const token = localStorage.getItem('access_token');

export default () => (
    
   
       


         <iframe title="Aqua Dashboard" src={`https://aqua-dash.tarf.io/?token=${token}`} scrolling="no" overflow="hidden" width="100%" height="2000" frameBorder="0" allowFullScreen ></iframe>
         
      
      


    
    
);




