import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';
import {useRecoilState } from 'recoil';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { connConfigs, deviceConfigs} from "../state/atoms";



const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  backgroundColor: theme.palette.background.paper,
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  

 
}));

export default function BasicGrid() {

  const [userDeviceConfigs, setuserDeviceConfigs] = useRecoilState(deviceConfigs);


  const handleTextFieldChange = (event) => {



    const { name, value } = event.target;


    setuserDeviceConfigs( {
      ...userDeviceConfigs,
      [name]: value

  });

    
    console.log ("userDeviceConfigs are: ", userDeviceConfigs)

    

    
  };

  function handlesave()
  
  {

  

  console.log ("device configs combined are: ", userDeviceConfigs)

 

}



  return (
    <Box 
    component="form"
      
    >
      <Grid container>
        <Grid xs={4} >
          {/* <Item>xs=4</Item> */}
        </Grid>
        <Grid >
      <Item>
      {/* <Item>xs=4</Item> */}
      <Box    
      
      
    >
    
    <div><TextField id="ssid" name ="ssid" label="SSID" variant="outlined"  onChange={handleTextFieldChange} style={{ marginTop: '20px' , marginBottom: "20px",   WebkitTextFillColor: "white"}}/></div>
    <div><TextField id="Wireless Password" name ="net_pass"  label="Wireless Password" variant="outlined" onChange={handleTextFieldChange} style={{marginTop: '20px' , marginBottom: "20px",WebkitTextFillColor: "white"}} /></div>

    <div> <TextField id="Username" name ="user_name" label="IOTSmart Username" variant="outlined" onChange={handleTextFieldChange} style={{ marginTop: '20px' , marginBottom: "20px", WebkitTextFillColor: "white"}}/></div>
    <div><TextField id="Password" name ="user_password" label="IOTSmart Password" variant="outlined" onChange={handleTextFieldChange}  style={{marginTop: '20px' , marginBottom: "20px", WebkitTextFillColor: "white"}} /></div>
    <div><Button  variant = "contained" onClick={() => handlesave()} style={{marginTop: '20px' , marginBottom: "20px", WebkitTextFillColor: "white"}}>Save Settings</Button></div>
    
     
      
     
    </Box>
    </Item>
        </Grid>
        <Grid xs={4}>
          {/* <Item>xs=4</Item> */}
        </Grid>
       
      </Grid>
    </Box>
  );
}


