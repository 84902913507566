// in src/MyLayout.js
import React from "react";
import {Layout} from 'react-admin';
import {MyAppBar} from './components/AppBar';
import MyMenu from './components/menu';



const MyLayout = (props) => < Layout {...props} menu = {MyMenu} appBar={MyAppBar}/>;

export default MyLayout;



