import React from 'react'
import { useState, useEffect ,useRef } from 'react';
import Map from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import { Marker, Popup } from 'react-map-gl';
import RoomIcon from '@mui/icons-material/Room';
import "mapbox-gl/dist/mapbox-gl.css";
import './maps.css'
import { useQuery } from "react-query";
import jwt_decode from "jwt-decode";
import { getDevices, getdeviceParameters} from './graphreq'



function Devicemap() {

  const isMounted = useRef(false);
  const token1 = localStorage.getItem('access_token');

  const [token, setToken] = useState(token1);

  const [currentPlaceId, setcurrentPlaceId] = useState();

  const [newPlace, setNewPlace] = useState();

  const [deviceParameter, setdeviceParameter] = useState("temperature");

const { REACT_APP_MAPTILLER_API_KEY } = process.env;

  var decoded = jwt_decode(token);


  var userid = decoded.identity;

  //// using useEffect below as we need to fetch the new parameter of the new device after updating device/locationid and avoid racing

  

  const HandlecurrentLocation = (id) => {

    setcurrentPlaceId(id)
       setdeviceParameter("temperature") 
       

    
  }

  const {
    isLoading: loadingDevices,
    error: errorDevices,
    data: userDevices,
   
  } = useQuery(

    ['devices'],
    getDevices,
    
  )

///// react-query below will be called only after calling refetch from useEffect
  const {
    isLoading: loadingParameters,
    error: errorParameters,
    data: deviceParams,
    refetch: refetchparameters,
  } = useQuery(

    ['parameters'],
    ()=> getdeviceParameters(currentPlaceId,"temperature"),
    {
      enabled: false,
    }
   
  )


  useEffect (() => {

    setInterval(() => {
     if(isMounted.current && currentPlaceId){


       refetchparameters();
 
 
     }else {
 
       isMounted.current = true;
     }
     
    //  console.log("current place id is set equal to: ", currentPlaceId ) 
   }, 15000);

  


 },[currentPlaceId,refetchparameters])

  if (loadingParameters) return "Loading...";
if (errorParameters) return <pre>{errorParameters.message}</pre>;

  

  if (loadingDevices) return "Loading...";
  if (errorDevices) return <pre>{errorDevices.message}</pre>;

 

 

  // console.log("users devices", userDevices)


  // console.log("device parameters are : ", deviceParams)

  const handleAddPlace = (e) => {
    const { lat, lng } = e.lngLat;

    // setNewPlace (e.loglat)

    setNewPlace({
      lat,
      lng,
    }
    )

    // console.log(e)

    console.log(newPlace)

  }


  return (
    <>


{console.log("testing env variables: ", process.env.REACT_APP_MAPTILLER_API_KEY ) } 
      <Map

        mapLib={maplibregl}
        initialViewState={{
          longitude: 46.583707,
          latitude: 24.664004,
          zoom: 8
        }}
       
        style={{ width: "100vw", height: "100vh" }}
        mapStyle="https://api.maptiler.com/maps/streets/style.json?key=EgZ6FBEfeNZZnu6Y3WA3"
        // mapStyle="https://api.maptiler.com/maps/streets/style.json?key=`${REACT_APP_MAPTILLER_API_KEY}`"
        // mapStyle="https://demotiles.maplibre.org/style.json"
      >


        {userDevices.device && userDevices.device.map((device) => (

          <>
            {
              device.customer.id === userid &&

              <Marker

                longitude={device.longitude}
                latitude={device.latitude}
                anchor="bottom"

              >

                <RoomIcon className='Droppin' key={device.id} onClick={ () => {HandlecurrentLocation(device.id) }  } />

              </Marker>

            }

            {device.id && (device.id === currentPlaceId) && (

              <Popup
                longitude={device.longitude}
                latitude={device.latitude}
                anchor="left"
                closeButton={true}
                closeOnClick={false}
                onClose={() => { setNewPlace(null) }}
              >
                <div >
                
                  <label>Device Name</label>
                  <h4 className='DeviceName'>{device.devicename}</h4>

                  <label>Parameters</label>
                 {
                    deviceParams  && 
                    
                    <div className='Params'>                 
                   
                    {(deviceParams.temperature !=0) && <h4 className='Parameter'>Temperature: {deviceParams.temperature} </h4> }
                    {(deviceParams.ph !=0) && <h4 className='Parameter'>PH: {deviceParams.ph} </h4>} 
                    {(deviceParams.oxygen !=0) && <h4 className='Parameter'>Oxygen: {deviceParams.oxygen} </h4>} 
                    {(deviceParams.tds !==0) && <h4 className='Parameter'>TDS: {deviceParams.tds} </h4>} 
                    {(deviceParams.salinity !==0) && <h4 className='Parameter'>Salinity: {deviceParams.salinity} </h4>} 
                    {(deviceParams.conductivity !==0) && <h4 className='Parameter'>Conductivity: {deviceParams.conductivity} </h4> }
                    {(deviceParams.orp !==0) && <h4 className='Parameter'>ORP: {deviceParams.orp} </h4>} 
               
                  
                  </div>
                    
                 }

                   <label>Location Name</label>
                  <h4 className='Parameter'>{device.location}</h4>
                  )
                     
 
                  )
                 
                 

                </div>

              </Popup>)

            }

          </>

        )

        )
        }


      </Map>





    </>
  )
}



export default Devicemap