import React from "react";
import { request, gql } from "graphql-request";
import { useQuery } from "react-query";



const endpoint = "https://sanam-hasura.tarf.io/v1/graphql";

const token = localStorage.getItem('access_token');

const requestHeaders = {
    Authorization: "Bearer " + token,
  }



export function ReturnQuery (id, parameter) {

const Query = 

gql` 
{
chquery(args: {sql: "select * from aqua where clientid=$id order by time DESC  limit 1"}) 

{ response }

}
`;

const Query1 =  "{chquery(args: {sql: " + '"' + "select " + parameter + " from aqua where clientid=" + "'" + id + "'" + " and " + parameter + " !=0" + " order by time DESC  limit 1" + '"' + "})" +"{response}}" 

const Query2 = "{chquery(args: {sql: select temperature from aqua where clientid='9313f731-0504-4ec1-9522-c9f730104005' and  temperature !=0 order by time DESC  limit 1}) {response}}"

const Query3 =  "{chquery(args: {sql: " + '"' + "select * from aqua where clientid=" + "'" + id + "'" + "order by time DESC  limit 1" + '"' + "})" + "{response}}" 


return Query3;

}

const PARAMETERS_QUERY = gql` 
{
    chquery(args: {sql: "select * from aqua where temperature !=0 order by time DESC  limit 10"}) {
      response
    }
  }
  
    
    `;

   export const PARAMETERS_QUERY1 = gql` 
{
    ReturnQuery(9313f731-0504-4ec1-9522-c9f730104005, "temperature")

    }
  }
  
    
    `;

const TEMP_QUERY = gql`

{
    chquery(args: {sql: "select temperature, time from aqua where temperature !=0 order by time DESC  limit 1"}) {
        response
      }
}
`;

const PH_QUERY = gql`

{
    chquery(args: {sql: "select ph, time from aqua where ph !=0 order by time DESC  limit 1"}) {
        response
      }
}
`;

const TDS_QUERY = gql`

{
    chquery(args: {sql: "select tds, time from aqua where tds !=0 order by time DESC  limit 10"}) {
        response
      }
}
`;

const CONDUCTIVITY_QUERY = gql`

{
    chquery(args: {sql: "select conductivity, time from aqua where conductivity !=0 order by time DESC  limit 1"}) {
        response
      }
}
`;


const OXYGEN_QUERY = gql`

{
    chquery(args: {sql: "select oxygen, time from aqua where oxygen !=0 order by time DESC  limit 1"}) {
        response
      }
}
`;

const ORP_QUERY = gql`

{
    chquery(args: {sql: "select orp, time from aqua where orp !=0 order by time DESC  limit 1"}) {
        response
      }
}
`;

const SALINITY_QUERY = gql`

{
    chquery(args: {sql: "select salinity, time from aqua where salinity !=0 order by time DESC  limit 1"}) {
        response
      }
}
`;


    const DEVICE_QUERY = gql`

 {
  device{
    id
    devicename
    latitude
    longitude
    customer_id
    sensor_data
    location
    customer {
      first_name
      id
    }
  }
}

  
`;

const DEVICE_CONTROLS  = gql` {
  controls {
    control_pin
    control_name
    device {
      id
      customer_id
    }
  }
}


`;


const  CONTROLS  = gql `{
  controls(where: {device_id: {_eq: $deviceid}}) {
    control_pin
    control_name
    device {
      id
      customer_id
    }
  }
}
`;


const query = gql`
  query getMovie($title: String!) {
    Movie(title: $title) {
      releaseDate
      actors {
        name
      }
    }
  }
`

const variables = {
  title: 'Inception',
}


export const  getDevices = async ()  => {   
   
    const deviceData = await request({ url: endpoint, document: DEVICE_QUERY, requestHeaders: requestHeaders })

return deviceData ;

}


export const  getParameters = async ()  => {    
   
   const deviceParameters = await request({ url: endpoint, document: PARAMETERS_QUERY, requestHeaders: requestHeaders })

return deviceParameters.chquery.response.data ;
}


export const  getdeviceParameters = async (id,parameter)  => {    

    
   
    const deviceParameters = await request({ url: endpoint, document: ReturnQuery(id,parameter), requestHeaders: requestHeaders })

    // console.log("from getdeviceParameters data is :" , deviceParameters.chquery.response.data[0] )

    if(deviceParameters.chquery.response.data[0])
 
            return deviceParameters.chquery.response.data[0] 

    else{

        return null
    }


 }

////select temperature, time, clientid from aqua where clientid = '9313f731-0504-4ec1-9522-c9f730104005' and  temperature !=0 order by time DESC  limit 10 


export function ReturnParameters () {


    const {
            isLoading: loadingParameters,
            error: errorParameters,
            data: deviceParams,
          } = useQuery(
        
            ['parameters'],
            ()=> getParameters(),
           
          )
        
          if (loadingParameters) return "Loading...";
        if (errorParameters) return <pre>{errorParameters.message}</pre>;

        console.log(deviceParams);

return (
<>
{
    deviceParams && deviceParams.map((param) => <label>{param.temperature}</label>)

}
</>

)

}