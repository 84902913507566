
import React from 'react'
import Typography from '@mui/material/Typography';



function Wizardtext(step) {
  /// javascript switch case



  switch (step.step) 
  {
   
    case 0:
           return <Typography variant="h6" component="div" sx={{ mt: 4, mb: 2, py: 2 , textAlign: 'center'}}></Typography>
        case  1:
            return <Typography variant="h6" component="div" sx={{ mt: 4, mb: 2, py: 2 , textAlign: 'center'}} >Select a Device and its predefined Sensor and Actuator</Typography>
            case 2:
                return <Typography variant="h6" component="div" sx={{ mt: 4, mb: 2, py: 2 , textAlign: 'center'}}>Select a programming language</Typography>
                case 3:
                  return <Typography variant="h6" component="div" sx={{ mt: 4, mb: 2, py: 2 , textAlign: 'center'}}>Select your connectivity parameters </Typography>
                case  4:
                    return <Typography variant="h6" component="div" sx={{ mt: 4, mb: 2, py: 2 , textAlign: 'center'}}>Review and submit </Typography>
                    

    default:
        return <Typography variant="h6" component="div" sx={{ mt: 4, mb: 2, py: 2 , textAlign: 'center'}}></Typography>
  }
}



export default Wizardtext;


// Compare this snippet from aqua-admin\src\components\DeviceWizard\devicewizard.js:  
