import React, { useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { deviceConfigs , userProgram} from '../state/atoms'
import { sendProgRequest } from './requests';
import {useRecoilState} from 'recoil';
import jwt_decode from "jwt-decode";
import { Download } from './download';




export default function DialogSelect() {
  const [open, setOpen] = React.useState(false);
  const [userDeviceConfigs, setuserDeviceConfigs] =  useRecoilState(deviceConfigs);
  const [result, setResult] = useState(false);
  const [Program] =  useRecoilState(userProgram);
 
  const token1 = localStorage.getItem('access_token');
  const [token] = useState(token1);
  var decoded = jwt_decode(token);
  var userid = decoded.identity;
  



// configs = { ...configs, }

  const handleSubmit = (event) => {
    const {
      target: { value },
    } = event;
   
// console.log("userDeviceConfigs in submit is : ", configs)

// console.log("Current Program in submit is : ", Program)


  
    const Reqresult = sendProgRequest(userDeviceConfigs.id, userDeviceConfigs.sensor_name , userDeviceConfigs.sensor_pin, userDeviceConfigs.sensor_id, userDeviceConfigs.read_frequency, userDeviceConfigs.control_name, 
      userDeviceConfigs.control_pin, userDeviceConfigs.control_id, userDeviceConfigs.program, userid,
      userDeviceConfigs.read_parameter, userDeviceConfigs.ssid, userDeviceConfigs.net_pass, userDeviceConfigs.user_name, userDeviceConfigs.user_password )

      setResult(Reqresult);


  };

const handleDownload = (event) => {


  Download(`https://event.tarf.io/download_file/${userDeviceConfigs.user_name}`)



}
  

   
  

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' ,  mt: 10, mb: 5, flexWrap: 'wrap' }}>
           
      <Box sx={{ display: 'flex', justifyContent: 'center' ,  width: '100%' }}>

      <Button onClick={handleSubmit}>Submit to get the program</Button>
      </Box>
            
   

      </Box>


      <Box sx={{ display: 'flex', justifyContent: 'center' ,  mt: 10, mb: 5, flexWrap: 'wrap' }}>
           
           <Box sx={{ display: 'flex', justifyContent: 'center' ,  width: '100%' }}>
     
           { result && <Button onClick={handleDownload}>Download Your programming files</Button>}
           </Box>
                 
        
     
           </Box>
    </>
  );
}