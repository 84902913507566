import React, { useEffect } from 'react';
import {
    ChipField, SingleFieldList, ReferenceManyField,
    ReferenceField, SelectInput, ReferenceInput, RichTextField, Create, List,
    Datagrid, TextField, Edit, SimpleForm, TextInput,
    useListContext
} from 'react-admin';

import { useRecoilState , useRecoilValue , useRecoilValueLoadable} from 'recoil';
import { selectedDevice , deviceConfigs  } from './state/atoms';


export const Aside = () => {
    const { isLoading, selectedIds } = useListContext();
    const [userSelectedDevice, setSelecteddevice] = useRecoilState(selectedDevice);
   
  
   

    useEffect (() => {

        setSelecteddevice(selectedIds)    
  

        console.log("Device selectedIds are :" ,userSelectedDevice)      

        
    },[selectedIds])

   
       
   
   
    return (
        <div>

         

             
          
            
        </div>
    );
};

export const DeviceList = props => {
 
    const [userSelectedDevice, setSelecteddevice] = useRecoilState(selectedDevice);
    const [userDeviceConfigs, setuserDeviceConfigs] = useRecoilState(deviceConfigs);

 
    
      
      useEffect (() => {    
       
        
     console.log("deviceconfigs inside DeviceList" , userDeviceConfigs)
    },[userSelectedDevice])
   
     

 

    
    

    return (
        <List {...props}
            aside={<Aside />}

        >
            <Datagrid rowClick="edit">
                <RichTextField source="devicename" />

                <ReferenceManyField label="Device's Sensors" reference="sensors" target="device_id">
                    <SingleFieldList>
                        <ChipField source="sensor_name" />
                    </SingleFieldList>
                </ReferenceManyField>


                <ReferenceManyField label="Sensor Parameter" reference="sensors" target="device_id">
                    <SingleFieldList>
                        <ChipField source="read_parameter" />
                    </SingleFieldList>
                </ReferenceManyField>
            


                <ReferenceManyField label="Device's Actuators" reference="controls" target="device_id">
                    <SingleFieldList>
                        <ChipField source="control_name" />
                    </SingleFieldList>
                </ReferenceManyField>
               

                <RichTextField source="location" />

               
                <TextField source="description" />
                {/* First source is the link to the foreign table which shoulf be the id , the second source is what we want to display in the view */}
                <ReferenceField source="systemid" reference="Systems"><TextField source="system_name" /></ReferenceField>
            </Datagrid>
            
        </List>
    );
}


export const DeviceEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="location" />
            <ReferenceInput source="customer_id" reference="customer"><SelectInput optionText="id" /></ReferenceInput>
            <TextInput source="devicename" />
            {/* <TextInput source="id" /> */}
            <TextInput source="description" />
            <TextInput source="latitude" />
            <TextInput source="longitude" />
            <ReferenceInput source="systemid" reference="Systems"><SelectInput optionText="system_name" /></ReferenceInput>

        </SimpleForm>
    </Edit>
);

export const DeviceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="location" />
            <TextInput source="devicename" />
            <TextInput source="description" />
            {/* <TextInput source="id" /> */}
            <ReferenceInput source="customer_id" reference="customer"><SelectInput optionText="id" /></ReferenceInput>
        </SimpleForm>
    </Create>
);