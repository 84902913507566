import * as React from 'react';
import { defaultTheme, Layout, AppBar, ToggleThemeButton } from 'react-admin';
import { createTheme, Box, Typography } from '@mui/material';
import { indigo , pink, red , deepOrange , purple , grey }  from '@mui/material/colors';

const darkTheme = createTheme({
    palette: { mode: 'dark' },
});

const lightTheme = createTheme({
    palette: { mode: 'light' },
});

const theme1 = createTheme({
  
    palette: {
      type: 'dark', // Switching the dark mode on is a single property value change.
      mode: 'dark',
      primary: pink,
      secondary: pink,
      error: red,
      contrastThreshold: 3,
      tonalOffset: 1,
      contrastText: '#000000',
      color: '#000000',

      background: {
          default: "#303030",
          paper: "#424242",
        }
     
    },
   
 
   sidebar: {
     width: 240, // The default value is 240
     closedWidth: 0, // The default value is 55
     
   }
});





export const MyAppBar = props => (
    <AppBar {...props}>
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title"></Typography>
        </Box>
        <ToggleThemeButton
            lightTheme={lightTheme}
            darkTheme={theme1}
        />
    </AppBar>
);

// const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;