import React from "react";
import { request, gql } from "graphql-request";



const endpoint = "https://sanam-hasura.tarf.io/v1/graphql";

const token = localStorage.getItem('access_token');

const requestHeaders = {
  Authorization: "Bearer " + token,
}



const PARAMETERS_QUERY = gql`{
    controls(where: {device_id: {_eq: $deviceid}}) {
      control_pin
      control_name
      device {
        id
        customer_id
      }
    }
  }
  `;

const CONFIGS = gql`  {
   customer_devices_controls  {
      id
      sensor_pin
      sensor_id
      read_frequency
      control_pin
      control_id
    }
  }
  
  `;


const CONFIG_QUERY = gql`
  query MyQuery($id: uuid) {
    customer_devices_controls(where: {id: {_eq: $id}}) {
      id
      sensor_name
      sensor_pin
      read_parameter
      sensor_id
      read_frequency
      control_name
      control_pin
      control_id
    }
  }
  
  `;

const query = gql`
  query getMovie($title: String!) {
    Movie(title: $title) {
      releaseDate
      actors {
        name
      }
    }
  }
`



const MAKE_PROG = gql`mutation MyMutation($control_id: Int, $control_name: String, $control_pin: Int!, $id: uuid!, $read_frequency: Int!, $sensor_id: uuid, $sensor_name: String, 
  $sensor_pin: Int!, $language: Int!, $user_id: Int!, $read_parameter: String,
  $ssid: String, $net_pass: String, $user_name: String, $user_password: String) 
  
  {
  insert_programs(objects: {control_id: $control_id, control_name: $control_name, control_pin: $control_pin, id: $id, read_frequency: $read_frequency, sensor_id: $sensor_id, sensor_name: $sensor_name, 
    sensor_pin: $sensor_pin, language: $language, user_id: $user_id,
    read_parameter: $read_parameter,
  ssid: $ssid,net_pass: $net_pass, user_name: $user_name, user_password: $user_password  }) 

  {
    __typename
    returning {
      control_id
      control_name
      control_pin
      id
      language
      read_frequency
      sensor_id
      sensor_name
      sensor_pin
      user_name
      net_pass
      ssid
      user_password
      read_parameter
      time
    }
  }
}


`;






export const getParameters = async (id) => {


  const variables = {

    id: id[0]
  }

  if (id != null) {

    const deviceParameters = await request({ url: endpoint, document: CONFIG_QUERY, variables: variables, requestHeaders: requestHeaders })

    console.log("returned device config from getParameters are:", deviceParameters.id)

    return deviceParameters
  }


  return null


}



export const sendProgRequest = async (id, sensor_name, sensor_pin, sensor_id, read_frequency, control_name, 
  control_pin, control_id, language, user_id, read_parameter,
  ssid,net_pass, user_name, user_password) => {


  const variables = {
    id: id,
    sensor_name: sensor_name,
    sensor_pin: sensor_pin,
    sensor_id: sensor_id,
    read_frequency: read_frequency,
    control_name: control_name,
    control_pin: control_pin,
    control_id: control_id,
    language: language,
    user_id: user_id,
    read_parameter: read_parameter,
    ssid: ssid,
    net_pass: net_pass,
    user_name: user_name,
    user_password: user_password

  }

  if (id != null) {

    const deviceProgramAck = await request({ url: endpoint, document: MAKE_PROG, variables: variables, requestHeaders: requestHeaders })

    console.log("returned device config from getParameters are:", deviceProgramAck)

    return deviceProgramAck
  }


  return null


}


