import React , { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { getParameters } from './requests';
import { userProgram, selectedDevice , deviceConfigs  } from '../state/atoms';
import {useRecoilState } from 'recoil';



export default function DialogSelect() {
  const [open, setOpen] = React.useState(false);
  const [program, setProgram] = useRecoilState(userProgram);
  const [userDeviceConfigs, setuserDeviceConfigs] = useRecoilState(deviceConfigs);
  const [userSelectedDevice, setSelecteddevice] = useRecoilState(selectedDevice);

  const { isLoading: loadingConfigs, error: errorConfigs, data: deviceParameters, refetch: refetchparameters,
   
  } = useQuery(

    ['configs'],
    ()=> getParameters(userSelectedDevice),
    
  )


useEffect (() => {

    console.log("Device selectedIds are :" ,userSelectedDevice)

    if(userSelectedDevice)
    {
      refetchparameters()

    }

    
    
},[userSelectedDevice,refetchparameters])



  
  if(userSelectedDevice)
  {
        if (loadingConfigs) return "Loading...";
        if (errorConfigs) return <pre>{errorConfigs.message}</pre>;
  }



 

  const handleChange = (event) => {
    setProgram(Number(event.target.value));

    console.log("program is set to:" , program)

    const { name, value } = event.target;


    setuserDeviceConfigs( {
      ...deviceParameters.customer_devices_controls[0],
      [name]: value

  });


  console.log("device configurations after adding program name" , userDeviceConfigs)
    

  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };


  return (
    <>
                
    <Box sx={{ display: 'flex', justifyContent: 'center' ,  mt: 10, mb: 5, width: '100%' }}>
      <Button onClick={handleClickOpen} variant="contained">Please Select Programming Language used by the device</Button>
      </Box>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Please Select a Programming Language to be used by the device </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'center' }}>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel htmlFor="demo-dialog-native">device</InputLabel>
              <Select
                value={program}
                name="program"
                onChange={handleChange}
                multiple={false}
                labelId="programID"
                id="programID"  
                input={<OutlinedInput label="device" id="demo-dialog-native" />}
              >
                             
              <MenuItem value={0}>
                  <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Python</MenuItem>
                  <MenuItem value={20}>MicroPython</MenuItem>
                  <MenuItem value={30}>Arduino-like C language </MenuItem>
              </Select>
            </FormControl>
            
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>


    </>
  );
}