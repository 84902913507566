import React from 'react';
import { MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import DefaultIcon from '@mui/icons-material/ViewList';
import makeStyles from '@mui/styles/makeStyles';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocationOnIcon from '@mui/icons-material/LocationOn';



const Menu1 = ({ onMenuClick, logout }) => {
   
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [open,setOpen] = React.useState(false);
   

    const resourcesDefinitions = useResourceDefinitions();

    const [monitoropen, setMonitorOpen] = React.useState(true);
    const [adminopen, setAdminOpen] = React.useState(true);



    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);

  

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            maxWidth: 2000,
            backgroundColor: theme.palette.background.paper,
            // primary: {
            //     main: pink[400]
            //   }
        },
        nested: {
            paddingLeft: theme.spacing(5),
        },
    }));

    const classes = useStyles();

        


    const handleClick = () => {
        setAdminOpen(!adminopen);
    };

    

const handleMonitor = () => {
  
    setMonitorOpen(!monitoropen);
    

};

   

        return (
        <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader className={classes.root} component="div" id="nested-list-subheader">
                        
                     </ListSubheader>
                }
                className={classes.root}
            >
                
                <ListItem button onClick={handleMonitor}>
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Monitoring" />
                    {monitoropen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={monitoropen} timeout="auto" unmountOnExit>
                    <List component="div" className={classes.nested}>

                        <MenuItemLink 
                            to={`/`}
                            primaryText={"Dashboard"}
                            leftIcon={<AssessmentIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open} />

                         <MenuItemLink 
                            to={`/Devicemap`}
                            primaryText={"Devicemap"}
                            leftIcon={<LocationOnIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open} />
                       
                       
                    </List>


                </Collapse>


                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <SettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Admin & Control" />
                    {adminopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={adminopen} timeout="auto" unmountOnExit>
                    <List component="div" className={classes.nested} >
                        
                       


                        {
                            resources.map(resource => ((resource.name.includes("Systems") || resource.name.includes("device") || resource.name.includes("controls") || resource.name.includes("sensors") || resource.name.includes("Rules") || resource.name.includes("alerts")) || resource.name.includes("Aquaponics") || resource.name.includes("rules"))?   
                            
                                <MenuItemLink key={resource.name}
                                    to={ `/${resource.name}`}
                                    primaryText={(resource.options && resource.options.label) || resource.name}
                                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                                    onClick={onMenuClick}
                                    sidebarIsOpen={open} />                                                             
                                : null
                                    

                            )

                            

                        }         

                         {

                            <MenuItemLink 
                            to={`/devicewizard`}
                            primaryText={"DeviceWizard"}
                            leftIcon={<AssessmentIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open} />



                        }  

                    </List>
                </Collapse>
            </List>
        );
    
}
export default (Menu1);
