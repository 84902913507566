
// buidl a function to download a file from a server using click button 



import axios from 'axios'


export const Download = (url) => {
    // Get the value of the input field
    

    // Use Axios to download the file
    axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', 'prog-files.zip'); // set the file name
        // document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        console.log(error);
    });
}


    