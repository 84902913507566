import React from 'react';
import { ChipField,SingleFieldList,ReferenceManyField,ReferenceField ,SelectInput,ReferenceInput, RichTextField, Create, List, Datagrid, TextField, Edit, SimpleForm, TextInput, Show, SimpleShowLayout, DateField ,EditButton, EmailField } from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

export const AlertList = props => (
   
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="deviceid" />
            {/* <TextField source="systemid" /> */}
            <TextField source="alerttext" />
        </Datagrid>
    </List>

  );

 