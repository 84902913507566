import React, { useEffect } from 'react';
import { Admin, localStorageStore, Resource, fetchUtils, ListGuesser, EditGuesser, ShowGuesser, CustomRoutes  } from 'react-admin';
import hasuraDataProvider from 'ra-data-hasura';
import { DeviceList, DeviceEdit, DeviceCreate } from './components/devices'
import { ControlList, ControlEdit, ControlCreate } from './components/controls'
import { SensorList, SensorEdit, SensorCreate } from './components/sensors'
import { aquacontrolList } from './components/aquaponic_controls_data'
import { SystemList, SystemEditList, SystemCreate } from './components/systems'
import { RulesList, RuleCreate, RuleEdit } from './components/Rules'
import { AlertList } from './components/alerts'
import authProvider from './authProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Dashboard from './components/dashboard'
import MyLayout from './layout';
import RouterIcon from '@mui/icons-material/Router';
import WavesIcon from '@mui/icons-material/Waves';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import DeviceWizard  from './components/DeviceWizard/devicewizard'
import Devicemap from './components/maps/devicemap';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Route } from "react-router-dom";




const token = localStorage.getItem('access_token');

const store = localStorageStore();
store.setItem('sidebar.open', true);


console.log("access token from main app", token)

const headers = { 'content-type': 'application/json', 'authorization': `Bearer ${token}` };


const dataProvider = hasuraDataProvider('https://sanam-hasura.tarf.io', headers);







function App () { 
 
  return(
  <div> 


    <Admin 
    
      dashboard={Dashboard}
      layout = {MyLayout}
      authProvider={authProvider}
      dataProvider={dataProvider} 
      store={store}
      
      >
      <Resource name="device" options={{ label: 'Devices' }} list={DeviceList} edit={DeviceEdit} create={DeviceCreate} icon={RouterIcon}/>
   
      <Resource name="controls" options={{ label: 'Controls' }} list={ControlList} edit={ControlEdit} create={ControlCreate} icon={KeyboardReturnIcon}/>

      <Resource name="sensors" options={{ label: 'Sensors' }} list={SensorList} edit={SensorEdit} create={SensorCreate} icon={AssessmentIcon}/>

      <Resource name="Systems" list={SystemList} edit={SystemEditList} create={SystemCreate} show={ShowGuesser} icon={DeviceHubIcon} />

      <Resource name="rules" options={{ label: 'Rules' }} list={RulesList} edit={RuleEdit} create={RuleCreate} show={ShowGuesser} icon={DeviceHubIcon} />

      <Resource name="alerts" options={{ label: 'Alerts' }} list={AlertList}  icon={DeviceHubIcon} />
         
      <Resource name="aquaponic_controls_data" options={{ label: 'Aquaponics' }} list={aquacontrolList} edit={EditGuesser} show={ShowGuesser} icon={WavesIcon}/>        
  
      <Resource name="actions"/>
      <Resource name="operators" />
      <Resource name="parameters" />

      <CustomRoutes>
            <Route path="/devicewizard" element={<DeviceWizard />} />
            <Route path="/devicemap" element={<Devicemap />} />        
      </CustomRoutes>

   
    </Admin>
  
    
  </div>
  )
  


}


export default App;


